/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)





import 'jquery';
import 'jquery-ujs';
//import 'jquery-migrate';
import 'popper.js';
import 'bootstrap';
import 'jquery-ui/ui/widgets/autocomplete'
import 'jquery.observe_field'
import './css/hs.core'
import './css/custom'
import './css/hs.header'
import './css/hs.hamburgers'
import './css/hs.megamenu'
import './css/hs.popup'
import './css/autocomplete'


import $ from 'jquery';

global.$ = jQuery;

$(document).on('ready', function () {
    // initialization of go to
    $.HSCore.components.HSGoTo.init('.js-go-to');

    // initialization of carousel
    $.HSCore.components.HSCarousel.init('.js-carousel');

    // initialization of popups
    $.HSCore.components.HSPopup.init('.js-fancybox');
});

$(window).on('load', function () {
    // initialization of header
    $.HSCore.components.HSHeader.init($('#js-header'));
    $.HSCore.helpers.HSHamburgers.init('.hamburger');

    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991
    });
});

$("#query").observe_field(.5, function() {
    $("#spinner").show(); // show the spinner
    const form = $(this).parents("form"); // grab the form wrapping the search bar.
    const url = form.attr("action"); // grab the URL from the form's action value.
    const formData = form.serialize(); // grab the data in the form
    return $.get(url, formData, function(html) { // perform an AJAX get, the trailing function is what happens on successful get.
        $("#spinner").hide(); // hide the spinner
        return $("#search_results").html(html);
    });
})

$(window).on('load', function () {
    $('.btn-action').click(function(){
        var url = $(this).data("url");
        $.ajax({
            type: "GET",
            url: url,
            dataType: 'html',

            success: function(res) {

                // get the ajax response data
                //var data = res.body;
                // update modal content
                $('.modal-body').replaceWith(res);
                // show modal
                $('#myModal').modal('show');

            },
            error:function(request, status, error) {
                console.log("ajax call went wrong:" + request.responseText);
            }
        });
    });
});




